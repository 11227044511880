.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7); // Fondo semitransparente
    z-index: 9999; // Asegura que esté por encima de todo
  }
  
  .loader {
    width: 50px;
    height: 50px;
    border: 6px solid #f58320; // Secundario
    border-top: 6px solid #FFCD00; // Principal
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  